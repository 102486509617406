import {ReactElement, useCallback, useEffect, useState} from "react";
import itMessages from 'devextreme/localization/messages/it.json';
import {locale, loadMessages} from "devextreme/localization";
import config from 'devextreme/core/config';
import Routes from "./routes";
import {StoreStateType} from "./store";
import {AuthDataType, authenticate, expire} from "./store/auth";
import {callFuncBeforeTokenExpiration, callFuncOnExpiration, jwtValidation} from "./utils/jwt";
import {connect} from "react-redux";
import AuthContext from "./context/auth";
import {Navbar} from "./components/navbar";
import axios from "./axios";

type AppProps = {
    auth: AuthDataType
    authenticate: Function
    expire: Function
}

const mapStateToProps = (state: StoreStateType) => ({
    auth: state.auth.data
})

const mapDispatchToProps = {
    authenticate,
    expire
}

export const App = connect(mapStateToProps, mapDispatchToProps)(
    (props: AppProps): ReactElement => {

        const [ready, setReady] = useState<boolean>(false);

        useEffect(() => {
            loadMessages(itMessages);
            // Set locale
            locale(navigator.language);
            config({
                editorStylingMode: 'underlined',
                defaultCurrency: 'EUR'
            });
            // Ready
            setReady(true);
        }, []);

        /**
         * Function called after authentication
         */
        const initAuthApp = useCallback(() => {

        }, []);

        /**
         * Refresh auth session
         */
        const refreshToken = useCallback(async (): Promise<boolean> => {
            // Call refresh token
            let result = await new Promise(resolve => resolve(false)); // @TODO define refresh token
            if (!result) return false;
            // Change auth session
            props.authenticate(result);
            return true;
        }, [props]);

        /**
         * Sign out from the current session
         */
        const signOut = useCallback(async () => {
            // Try to refresh token
            let refresh = await refreshToken();
            if (refresh) return;
            // Expire session
            props.expire();
        }, [props, refreshToken]);

        /**
         * Authentication listener
         */
        useEffect(() => {
            // Timeout instances
            let onExp: NodeJS.Timeout | null = null;
            let onLessTimeToExp: NodeJS.Timeout | null = null;
            // Check if auth
            if (props.auth && jwtValidation(props.auth.token)) {
                // Set headers
                axios.defaults.headers['Authorization'] = 'Bearer ' + props.auth.token;
                // Set expiration token call
                onExp = callFuncOnExpiration(props.auth.token, signOut);
                // Set refresh token
                onLessTimeToExp = callFuncBeforeTokenExpiration(props.auth.token, refreshToken);
                // Call init app
                initAuthApp();
            } else {
                // Sign out
                signOut().then(() => true);
            }
            // Unsubscribe
            return () => {
                if (onExp) clearTimeout(onExp);
                if (onLessTimeToExp) clearTimeout(onLessTimeToExp);
            }
        }, [props, signOut, refreshToken, initAuthApp]);

        return (
            <div id='app' className='h-screen w-screen overflow-auto'>
                <AuthContext.Provider value={props.auth}>
                    {
                        // Navbar
                        props.auth
                            ? <Navbar onSignOut={() => props.expire()}/>
                            : <></>
                    }
                    {
                        // Routes content
                        ready
                            ? <Routes/>
                            : <></>
                    }
                </AuthContext.Provider>
                {/* Version */}
                <small className='fixed bottom-0 left-0 text-xs'>{process.env.REACT_APP_VERSION}</small>
            </div>
        )
    }
)