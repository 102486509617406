import {ReactElement} from "react";

type HeaderTitleProps = {
    title: string
    inverse?: boolean
    cssClass?: string
}

export const HeaderTitle = ({title, inverse, cssClass}: HeaderTitleProps): ReactElement => {
    return (
        <div
            className={`header-title pb-1 ${inverse ? 'pt-5 px-2 bg-primary text-white' : 'text-primary'} ${cssClass}`}>
            <h2 className='text-xl'>{title}</h2>
        </div>
    )
}