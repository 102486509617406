import {ReactElement, useRef} from "react";
import {Button, DataGrid} from "devextreme-react";
import DataSource from "devextreme/data/data_source";
import ReactDOM from "react-dom";
import jsPdf from "jspdf";
import {exportDataGrid} from "devextreme/pdf_exporter";
import moment from "moment";

// Product provider definition
export type ProductProvider = {
    // Codice prodotto secondo il fornitore
    code: string
    // Desc. fornitore
    provider: Provider
    // Prezzo
    price: number
    // Prezzo di listino (from Product)
    listPrice?: number
    // Quantità
    quantity: number
    // Sconto
    discount?: number | null
}

export type Provider = {
    id: string | number,
    // Nome fornitore
    name: string
    // Codice interno
    internal_code: string
}

type ProductProviderTableProps = {
    dataSource: ProductProvider[] | DataSource
    enableExport?: boolean
}

export const ProductProviderTable = ({dataSource, enableExport}: ProductProviderTableProps): ReactElement => {

    const grid = useRef<DataGrid | null>(null);

    // Table export
    const exportGrid = () => {
        if (grid.current) {
            const doc = new jsPdf({orientation: 'l', format: 'a4'});
            const dataGrid = grid.current.instance;
            // Export
            exportDataGrid({
                jsPDFDocument: doc,
                component: dataGrid
            }).then(() => {
                // doc.autoPrint();
                // doc.output('dataurlnewwindow');
                doc.save('Tabella_Comparativa_' + moment().format('DD_MM_YYYY') + ' .pdf');
            });
        }
    };

    return (
        <div className="product-provider-table">
            <DataGrid
                ref={grid}
                dataSource={dataSource}
                columns={[
                    {dataField: 'code', dataType: 'string', caption: 'Codice', allowGrouping: true},
                    {dataField: 'provider.name', dataType: 'string', caption: 'Fornitore'},
                    {dataField: 'quantity', dataType: 'number', caption: 'Quantità'},
                    {
                        dataField: 'listPrice',
                        dataType: 'number',
                        caption: 'Prezzo di listino',
                        format: {
                            type: 'currency',
                            precision: 2
                        }
                    },
                    {
                        dataField: 'discount',
                        dataType: 'number',
                        caption: 'Sconto',
                        format: {
                            type: 'percent',
                            precision: 2
                        }
                    },
                    {
                        dataField: 'price',
                        dataType: 'number',
                        caption: 'Prezzo',
                        format: {
                            type: 'currency',
                            precision: 2
                        }
                    }
                ]}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                // Disable paging
                paging={{
                    enabled: false
                }}
                // Grouping
                groupPanel={{visible: false}}
                // Toolbar
                toolbar={{
                    visible: !!enableExport,
                    items: [
                        {
                            template: (this_, number, element) => {
                                // Render PDF
                                ReactDOM.render(
                                    <Button
                                        icon='pdffile'
                                        onClick={exportGrid}
                                    />, element
                                );
                            }
                        }
                    ]
                }}
                // Style
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                rowAlternationEnabled={true}
            />
        </div>
    )
}