import {ReactElement, useEffect, useState} from "react";
import {Redirect, Route, useHistory, useRouteMatch} from "react-router-dom";
import {ProductSelection} from "../../components/product-selection";
import {FilterType, ProductQuotation} from "../../components/product-quotation";
import {Product} from "../../components/product-table";

export const Quotations = (): ReactElement => {

    const {path} = useRouteMatch();
    const history = useHistory();

    const [selections, setSelections] = useState<Product[]>([]);
    const [quotationFilter, setQuotationFilter] = useState<FilterType | null>(null);
    useEffect(() => {
        if (quotationFilter) {
            // Redirect to /results table
            history.push(path + '/results');
        }
    }, [quotationFilter, history, path]);

    return (
        <div id='quotations'>
            {/* Index */}
            <Route exact={true} path={path}>
                <Redirect to={path + '/search'}/>
            </Route>

            {/* Pages */}
            <Route exact={true} path={path + '/search'}>
                {/* Search products for quotations */}
                <ProductSelection
                    prevSelection={selections}
                    onProductSelected={(products) => {
                        console.log('[onProductSelected] products', products);
                        setSelections(products);
                        // Create object filter
                        let filter: FilterType = {
                            products: products.map(p => {
                                return {productId: p.id, quantity: p.quantity}
                            }),
                            providers: [],
                            split: true,
                            stock: true
                        }
                        // Set default filter
                        setQuotationFilter(filter);
                    }}
                />
            </Route>
            <Route exact={true} path={path + '/results'}>
                {/* Table quotations */}
                <ProductQuotation defaultFilter={quotationFilter}/>
            </Route>
        </div>
    )
}