import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from "react-router-dom"
import {Provider as ReduxProvider} from "react-redux";
import store from "./store";
import {App} from './App';
import './index.scss';

import './dx.material.custom-scheme.css';

ReactDOM.render(
    <React.StrictMode>
        <ReduxProvider store={store}>
            <HashRouter basename='/'>
                <App/>
            </HashRouter>
        </ReduxProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
