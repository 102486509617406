import {ReactElement} from "react";
import {Button} from "devextreme-react";

type NavbarProps = {
    onSignOut: () => void
}

export const Navbar = (props: NavbarProps): ReactElement => {

    const openInPrint = () => {
        window.open('http://inprintbusiness.exec.it', 'IdentiPrint', 'width=1000,height=600,scrollbars=1,location=1,status=1,toolbar=1,menubar=0,resizable=1');
    }

    return (
        <div className='sticky top-0 flex justify-between items-center bg-white py-2 px-3.5 shadow z-10'>
            {/* Left */}
            <div className='flex flex-row gap-4 items-center'>
                {/* Logo */}
                <img src="assets/images/logo.jpg" alt="Logo InfoIn" className='max-h-16'/>
                {/* Button InPrint */}
                <Button
                    text='Vai a InPrint'
                    icon='fa-solid fa-print'
                    type='success'
                    onClick={openInPrint}
                />
            </div>
            {/* Sign Out */}
            <Button
                text='Esci'
                icon='fa-solid fa-arrow-right-from-bracket'
                stylingMode='text'
                onClick={props.onSignOut}
            />
        </div>
    )
}