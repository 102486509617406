import {createSlice} from '@reduxjs/toolkit'

// Authentication data type
export type AuthDataType = any | null; // @TODO replace any with auth data type

/**
 * @ TODO check saveLocal config
 * Define if save auth data into localStorage
 * string > property to check into payload
 * true > always
 * false > never
 */
const saveLocal: boolean | string = true;

// Get initial state
export const getInitialState = (): AuthDataType => {
    let local_ = localStorage.getItem('authentication');
    return local_ && JSON.parse(local_);
}

// Initial state
const initialState: { data: AuthDataType } = {
    data: getInitialState()
};

// Create redux slice
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authenticate(state, action) {
            // Check if save payload into local
            if(saveLocal === true || (saveLocal && action.payload[saveLocal])) {
                localStorage.setItem('authentication', JSON.stringify(action.payload));
            }
            // Set state
            state.data = action.payload;
        },
        expire(state) {

            console.log('MAKE EXP!');

            // Clear localStorage
            localStorage.removeItem('authentication');
            // Set null state
            state.data = null;
        }
    },
})

export default authSlice.reducer;
export const {authenticate, expire} = authSlice.actions