/**
 * Get the JWT expiration
 * @param token
 */

export const jwtExp = (token: string): number => {
    // Get JWT expiration
    let jwtObjects = token.split('.');
    let decoded = atob(jwtObjects[1]);
    return JSON.parse(decoded).exp;
}

/**
 * Check if the JWT token is valid
 * @param token
 */
export const jwtValidation = (token: string): boolean => {
    let exp = jwtExp(token);
    // Return if valid
    return !(Date.now() >= exp * 1000);
}

/**
 * Call function when token expire before 'time'
 * @param token
 * @param callback
 * @param time (default 1 day)
 */
export const callFuncBeforeTokenExpiration = (token: string, callback: Function, time: number = 1000 * 60 * 60 * 24): NodeJS.Timeout => {
    let exp = jwtExp(token) * 1000;
    let current = Date.now();
    let timer1DayBefore = exp - current - time;
    // Check if before 1 day
    let timer = timer1DayBefore > 0 ? timer1DayBefore : 0;
    // setTimeout
    return setTimeout(() => callback(), timer < 2147483647 ? timer : 2147483647);
}

/**
 * Call function on token expiration
 * @param token
 * @param callback
 */
export const callFuncOnExpiration = (token: string, callback: Function): NodeJS.Timeout => {
    let exp = jwtExp(token) * 1000;
    let current = Date.now();
    // Get timer
    let timer = exp - current;
    // Return timeout
    return setTimeout(() => callback(), timer < 2147483647 ? timer : 2147483647);
}