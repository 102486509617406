import {MutableRefObject, ReactElement} from "react";
import {DataGrid} from "devextreme-react";
import DataSource from "devextreme/data/data_source";
import ReactDOM from "react-dom";
import {ProductProvider, ProductProviderTable} from "../product-provider-table";

// Product definition
export type Product = {
    id: number | string
    // Codice prodotto
    code: string
    // Desc. produttore (es. HP)
    producer: string
    // Codice utente
    userCode?: string | null
    // Descrizione prodotto
    description?: string | null
    // Prezzo di listino
    listPrice: number
    // Lista info fornitori
    providers: ProductProvider[],
    // Quantity
    quantity?: number
}

type productTableProps = {
    grid?: MutableRefObject<DataGrid | null>
    dataSource: Product[] | DataSource,
    enableSelection?: boolean
    onRecordsSelected?: (selections: Product[]) => void
    onRecordDblClick?: (product: Product) => void
    enableQuantity?: boolean,
    allowQuantityEdit?: boolean
    enableDelete?: boolean
    onRecordDeleted?: (data: Product) => void
}

export const ProductTable = ({
                                 grid,
                                 dataSource,
                                 enableSelection,
                                 onRecordsSelected,
                                 onRecordDblClick,
                                 enableQuantity,
                                 allowQuantityEdit,
                                 enableDelete,
                                 onRecordDeleted
                             }: productTableProps): ReactElement => {

    const detailComponent = (element: any, cellData: any) => {
        let dataSource: ProductProvider[] = cellData.data.providers;
        // Filter for enabled providers Format with list_price
        dataSource = dataSource.filter(i => i.quantity !== null).map(i => {
            return {...i, listPrice: cellData.data.listPrice}
        })
        // Create detail view
        let detailView = <div>
            {/* Include product provider table */}
            <ProductProviderTable dataSource={dataSource}/>
            <p className='p-1 text-sm text-gray-500'>I prezzi sopra riportati non includono gli sconti a Voi riservati
                per quantità, trasporto, ecc.</p>
        </div>
        ReactDOM.render(detailView, element);
    }

    return (
        <div className="product-table">
            {/* Import Grid */}
            <DataGrid
                ref={grid}
                dataSource={dataSource}
                keyExpr='code'
                columns={[
                    {dataField: 'code', dataType: 'string', caption: 'Codice originale', allowEditing: false},
                    {
                        dataField: 'userCode',
                        dataType: 'string',
                        caption: 'Codice utente',
                        customizeText: (data: any) => data.value || '--',
                        allowEditing: false,
                        //hidingPriority: 1
                    },
                    {
                        dataField: 'providers',
                        dataType: 'string',
                        caption: 'Fornitori',
                        customizeText: (data: any) => data.value.map((item: any) => item.provider.name).join(', '),
                        allowEditing: false,
                        //hidingPriority: 2
                    },
                    {
                        dataField: 'producer',
                        dataType: 'string',
                        caption: 'Produttore',
                        allowEditing: false,
                        //hidingPriority: 3
                    },
                    {
                        dataField: 'description',
                        dataType: 'string',
                        caption: 'Descrizione',
                        allowEditing: false,
                        width: 400
                        //hidingPriority: 4
                    },
                    {
                        dataField: 'quantity',
                        dataType: 'number',
                        caption: 'Quantità',
                        visible: !!enableQuantity,
                        allowEditing: true,
                        showEditorAlways: true,
                        editorOptions: {
                            min: 1,
                            placeholder: 'Inserisci quantità desiderata',
                            stylingMode: 'filled',
                            showSpinButtons: true
                        },
                        validationRules: [
                            {type: 'required', message: 'Campo obbligatorio'}
                        ],
                        cssClass: 'bg-gray-100'
                    }
                ]}
                columnAutoWidth={true}
                columnHidingEnabled={false}
                // Remote
                remoteOperations={{
                    filtering: true,
                    paging: true
                }}
                // Selection
                selection={{
                    mode: enableSelection ? 'multiple' : 'none',
                    selectAllMode: 'page'
                }}
                onSelectionChanged={(args: any) => onRecordsSelected ? onRecordsSelected(args.selectedRowsData) : null}
                // Dbl Click event
                onCellDblClick={(args: any) => onRecordDblClick ? onRecordDblClick(args.data) : null}
                // Editable
                editing={{
                    allowUpdating: !!allowQuantityEdit,
                    allowDeleting: !!enableDelete,
                    mode: 'cell',
                    confirmDelete: false
                }}
                onRowRemoving={(args: any) => onRecordDeleted ? onRecordDeleted(args) : null}
                // Master / Detail
                masterDetail={{
                    enabled: true,
                    template: detailComponent
                }}
            />
        </div>
    )
}