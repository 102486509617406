import {ReactElement} from "react";
import {Redirect, Route, useRouteMatch} from "react-router-dom";

export const Home = (): ReactElement => {

    const {path} = useRouteMatch();

    return (
        <div id='home'>
            {/* Redirect to page */}
            <Route exact={true} path={path}>
                <Redirect to={path + 'quotations'}/>
            </Route>
        </div>
    )
}