import {ReactElement, useEffect, useState} from "react";
import {Button, TagBox} from "devextreme-react";

type ProductSearchFormProps = {
    filters: FilterType,
    onFilter: (filter: FilterType) => void
}

export type FilterType = {
    originalCodes: string[],
    userCodes: string[],
    providerCodes: string[],
    eanCodes: string[],
    keywords: string[]
}

export const ProductSearchForm = ({filters, onFilter}: ProductSearchFormProps): ReactElement => {

    const [originalCodes, setOriginalCodes] = useState<string[]>(filters.originalCodes);
    const [userCodes, setUserCodes] = useState<string[]>(filters.userCodes);
    const [providerCodes, setProviderCodes] = useState<string[]>(filters.providerCodes);
    const [eanCodes, setEanCodes] = useState<string[]>(filters.eanCodes);
    const [keywords, setKeywords] = useState<string[]>(filters.keywords);

    // Check user codes filter update
    useEffect(() => {
        // Check if user codes filter is empty
        if (filters.userCodes.length) {
            // Clear original codes filter
            setUserCodes(filters.userCodes);
        }
    }, [filters.userCodes]);

    const submit = (args: any): void => {
        args.preventDefault();
        // Callback
        onFilter({originalCodes, userCodes, providerCodes, eanCodes, keywords});
    }

    const cancel = () => {
        // Clear filters
        setOriginalCodes([]);
        setUserCodes([]);
        setProviderCodes([]);
        setEanCodes([]);
        setKeywords([]);
    }

    return (
        <form className='product-search-form space-y-5' onSubmit={submit}>
            {/* Original codes */}
            <TagBox
                value={originalCodes}
                label='Codici Originali'
                labelMode='floating'
                stylingMode='outlined'
                showClearButton={true}
                acceptCustomValue={true}
                noDataText='Inserisci i codici (premi Enter per inserire)'
                placeholder=''
                onValueChanged={(args: any) => setOriginalCodes(args.value)}
            />
            {/* User codes */}
            <TagBox
                value={userCodes}
                label='Codici Utente / InUfficio'
                labelMode='floating'
                stylingMode='outlined'
                showClearButton={true}
                acceptCustomValue={true}
                noDataText='Inserisci i codici (premi Enter per inserire)'
                placeholder=''
                onValueChanged={(args: any) => setUserCodes(args.value)}
            />
            {/* Providers codes */}
            <TagBox
                value={providerCodes}
                label='Codici Interni dei Fornitori'
                labelMode='floating'
                stylingMode='outlined'
                showClearButton={true}
                acceptCustomValue={true}
                noDataText='Inserisci i codici (premi Enter per inserire)'
                placeholder=''
                onValueChanged={(args: any) => setProviderCodes(args.value)}
            />
            {/* EAN codes */}
            <TagBox
                value={eanCodes}
                label='Codici EAN'
                labelMode='floating'
                stylingMode='outlined'
                showClearButton={true}
                acceptCustomValue={true}
                noDataText='Inserisci i codici (premi Enter per inserire)'
                placeholder=''
                onValueChanged={(args: any) => setEanCodes(args.value)}
            />
            {/* Keyword */}
            <TagBox
                value={keywords}
                label='Parole chiave'
                labelMode='floating'
                stylingMode='outlined'
                showClearButton={true}
                acceptCustomValue={true}
                noDataText='Inserisci le parole chiave (premi Enter per inserire)'
                placeholder=''
                onValueChanged={(args: any) => setKeywords(args.value)}
            />
            {/* Btn submit */}
            <Button
                text='Ricerca'
                icon='search'
                type='default'
                width='100%'
                useSubmitBehavior={true}
            />
            {/* Btn cancel */}
            <Button
                text='Annulla'
                type='normal'
                width='100%'
                onClick={cancel}
            />
        </form>
    )
}