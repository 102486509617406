import {ReactElement} from "react";
import {DataType, SignInForm} from "../../components/sign-in-form";
import {authenticate} from "../../store/auth";
import {connect} from "react-redux";
import axios from "../../axios";

type SignInProps = {
    authenticate: Function
}

const mapDispatchToProps = {
    authenticate
}

export const SignIn = connect(null, mapDispatchToProps)(
    (props: SignInProps): ReactElement => {

        const signInUser = async (data: DataType) => {
            // Call API
            await axios.post('/sign-in', data).then(result => {
                // Set axios
                axios.defaults.headers['Authorization'] = 'Bearer ' + result.data.data.token;
                // Authenticate user
                props.authenticate(result.data.data);
            }).catch(reason => {
                console.error('[SignIn] Error API', reason);
            });
        }

        return (
            <div id='sign-in' className='w-full h-full flex justify-center items-center bg-gray-200'>
                <div className="w-96 p-7 border bg-white shadow rounded-md">
                    <h1 className="text-3xl mb-7">Accedi</h1>
                    <SignInForm onSubmit={signInUser}/>
                </div>
            </div>
        )
    }
)