import axios from "axios";

// Create api
const api = axios.create({
    baseURL: process.env.REACT_APP_API || '/',
    headers: {
        Authorization: 'Bearer '
    }
});

// Handle responses
api.interceptors.response.use(
    // Success
    (response: any) => response,
    // Error
    (error: any) => Promise.reject(error)
);

export default api;