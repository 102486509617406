import {ReactElement, useState} from "react";
import {Button, TextBox} from "devextreme-react";

type SignInFormProps = {
    onSubmit: (data: DataType) => Promise<void>
}

export type DataType = {
    username: string
    password: string
}

export const SignInForm = (props: SignInFormProps): ReactElement => {

    const [data, setData] = useState<any>({});

    const submit = (args: any) => {
        args.preventDefault();
        // Validate
        if (data.username && data.password) {
            // Callback
            props.onSubmit(data).then(() => true);
        }
    }

    return (
        <form id="sign-in-form" onSubmit={submit} className='space-y-5'>
            {/* Username */}
            <TextBox
                name='username'
                label='Username'
                onValueChanged={(args) => setData({...data, username: args.value})}
            />
            {/* Password */}
            <TextBox
                name='password'
                label='Password'
                mode='password'
                onValueChanged={(args) => setData({...data, password: args.value})}
            />
            {/* Submit */}
            <Button
                text='Accedi'
                icon='fa-solid fa-arrow-right-to-bracket'
                type='default'
                useSubmitBehavior={true}
                width='100%'
            />
        </form>
    )
}