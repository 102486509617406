import {ReactElement, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Product, ProductTable} from "../product-table";
import {FilterType, ProductSearchForm} from "../product-search-form";
import {Panel} from "../panel";
import {Button, DataGrid} from "devextreme-react";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import axios from "../../axios";
import {useHistory, useLocation} from "react-router-dom";

type ProductSelectionProps = {
    prevSelection: Product[]
    onProductSelected: (products: Product[]) => void
}

export const ProductSelection = ({prevSelection, onProductSelected}: ProductSelectionProps): ReactElement => {

    // Query params
    const history = useHistory();
    const {search} = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);

    // State
    const codut = useMemo(() => queryParams.get('codut') || null, [queryParams]);

    // Define Product store
    const productStore = useMemo(() => (
        new CustomStore({
            key: 'id',
            load: (options) => {
                // Call API
                return axios.get('/products', {
                    params: {
                        filter: options.filter,
                        skip: options.skip,
                        limit: options.take
                    }
                }).then(results => results.data);
            }
        })
    ), []);

    const [results, setResults] = useState<DataSource>(new DataSource({
        store: [] // Empty
    }));
    const [selections, setSelections] = useState<Product[]>(prevSelection);

    const [currentSelections, setCurrentSelections] = useState<Product[]>([]);

    // Table results ref
    const tableResults = useRef<DataGrid | null>(null);
    const tableSelections = useRef<DataGrid | null>(null);

    // Dbl Click event
    const onDblClickAddRecord = useCallback((product: Product) => {
        let selections_ = [...selections];
        // Check if product already exists
        let index = selections_.findIndex(s => s.id === product.id);
        if (index === -1) {
            // Push record
            selections_.push({...product, quantity: 1});
            // Update list
            setSelections(selections_);
        }
    }, [selections])
    const onDblClickRemoveRecord = useCallback((product: Product) => {
        let selections_ = [...selections];
        // Find index
        let index = selections_.findIndex(s => s.id === product.id);
        if (index !== -1) {
            selections_.splice(index, 1);
            // Update selections
            setSelections(selections_);
        }
    }, [selections]);

    const TableResults = useMemo(() => {
        return <ProductTable
            grid={tableResults}
            dataSource={results}
            enableSelection={true}
            onRecordDblClick={onDblClickAddRecord}
            onRecordsSelected={(records: Product[]) => setCurrentSelections(records)}
        />
    }, [results, onDblClickAddRecord]);
    const TableSelections = useMemo(() => {
        return <ProductTable
            grid={tableSelections}
            dataSource={selections}
            onRecordDblClick={onDblClickRemoveRecord}
            enableQuantity={true}
            allowQuantityEdit={true}
            enableDelete={true}
            onRecordDeleted={(args: any) => {
                // Prevent delete
                args.cancel = true;
                let record: Product = args.data;
                // Update selections
                let selections_ = [...selections];
                let index = selections_.findIndex(i => i.id === record.id);
                if (index !== -1) {
                    selections_.splice(index, 1);
                    // Update data source
                    setSelections(selections_);
                }
            }}
        />
    }, [selections, onDblClickRemoveRecord])

    const onFilter = useCallback(async (data: any) => {
        // Setup new Data Source
        setResults(
            new DataSource({
                store: productStore,
                filter: JSON.stringify(data),
                pageSize: 15
            })
                .on('loadingChanged', () => tableResults.current?.instance.beginCustomLoading('Caricamento prodotti'))
                .on('changed', () => tableResults.current?.instance.endCustomLoading())
        );
    }, [productStore]);

    /**
     * Filter by query params
     */
    useEffect(() => {
        // Check codut filter
        if (codut) {
            // Create filter data object
            const filters: FilterType = {
                originalCodes: [],
                userCodes: [codut],
                providerCodes: [],
                eanCodes: [],
                keywords: []
            }
            // Call filter
            onFilter(filters).then(() => {
                // Clear query params
                history.replace({
                    search: ''
                });
            });
        }
    }, [codut, history, onFilter]);

    return (
        <div className='product-selection min-h-full w-full p-2 bg-gray-100'>
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                {/* Filter panel */}
                <Panel
                    headerTitle={{title: 'Ricerca prezzo', cssClass: 'mb-2'}}
                    cssClass='md:basis-1/4 lg:basis-1/5 shadow p-3'
                >
                    <ProductSearchForm
                        filters={{
                            originalCodes: [],
                            userCodes: codut ? [codut] : [],
                            providerCodes: [],
                            eanCodes: [],
                            keywords: []
                        }}
                        onFilter={onFilter}
                    />
                </Panel>
                {/* Results */}
                <div className="md:basis-3/4 lg:basis-4/5 shadow overflow-x-hidden">
                    <Panel headerTitle={{title: 'Risultati ricerca'}} cssClass='p-3'>
                        {
                            /* Results table */
                            TableResults
                        }
                        {/* Btn confirm selections */}
                        <div className='mt-2'>
                            <Button
                                text='Conferma selezionati'
                                type='default'
                                width='100%'
                                disabled={!currentSelections.length}
                                onClick={() => {
                                    // Create new selections
                                    let selections_ = [...selections];
                                    // Each current records selections
                                    for (const currentSelection of currentSelections) {
                                        let index = selections_.findIndex(i => i.id === currentSelection.id);
                                        if (index === -1) {
                                            // Add new with default quantity value
                                            selections_.push({...currentSelection, quantity: 1});
                                        }
                                    }
                                    // Update selections
                                    setSelections(selections_);
                                }}
                            />
                        </div>
                    </Panel>
                    <Panel headerTitle={{title: 'Prodotti selezionati'}} cssClass='p-3'>
                        {
                            /* Selection table */
                            TableSelections
                        }
                        {/* Quote selections */}
                        <div className="mt-2 text-right">
                            <Button
                                text='Quota prodotti'
                                type='success'
                                disabled={!selections.length}
                                onClick={() => onProductSelected(selections)}
                            />
                        </div>
                    </Panel>
                </div>
            </div>
        </div>
    )
}