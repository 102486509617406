import {ReactElement} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {useAuthContext} from "../context/auth";
import {Home} from "../pages/home";
import {SignIn} from "../pages/sign-in";
import {Quotations} from "../pages/quotations";

const Routes = (): ReactElement => {

    // Get current auth context
    const auth = useAuthContext();

    // Type route page
    type RoutePage = {
        path: string,
        component: any,
        exact?: boolean,
        /**
         * Protected
         * = true --> Route protected by authData
         * = false --> Route for not authenticated only!
         * = null --> Public
         */
        protected: boolean | null
    }

    // Routes types
    const ProtectedRoute = (route: RoutePage): ReactElement => {
        const location = useLocation();
        if (!auth) {
            console.log('ProtectedRoute, Redirect', {
                pathname: location.pathname,
                search: location.search
            });
            return (
                <Redirect
                    to={{
                        pathname: '/sign-in',
                        state: {
                            from: location.pathname,
                            search: location.search
                        }
                    }}
                />
            );
        }
        return <Route {...route} />;
    };
    const NotAuthRoute = (route: RoutePage): ReactElement => {
        const location = useLocation();
        if (auth) {
            console.log('NotAuthRoute, Redirect', {
                pathname: (location.state as any)?.from || '/',
                search: (location.state as any)?.search || ''
            });
            return (
                <Redirect
                    to={{
                        pathname: (location.state as any)?.from || '/',
                        search: (location.state as any)?.search || ''
                    }}
                />
            );
        }
        return <Route {...route} />;
    };
    const PublicRoute = (route: RoutePage): ReactElement => {
        return <Route {...route} />;
    };

    // Routes list
    const routes: RoutePage[] = [
        {path: '/', component: Home, exact: true, protected: true},
        {path: '/sign-in', component: SignIn, exact: true, protected: false},
        {path: '/quotations', component: Quotations, exact: false, protected: true}
    ];

    return (
        <Switch>
            {
                // Each routes
                routes.map((route: RoutePage, index) => {
                    // Check route type
                    if (route.protected) return <ProtectedRoute key={index} {...route} />
                    if (route.protected === false) return <NotAuthRoute key={index} {...route} />
                    return <PublicRoute key={index} {...route} />
                })
            }
        </Switch>
    );
}

export default Routes;