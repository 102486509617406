import {ReactElement, useCallback, useEffect, useRef, useState} from "react";
import {Button, DataGrid, Popup, Switch} from "devextreme-react";
import {Product} from "../product-table";
import {ProductProvider, ProductProviderTable, Provider} from "../product-provider-table";
import ReactDOM from "react-dom";
import {exportDataGrid} from "devextreme/pdf_exporter";
import jsPdf from "jspdf";
import 'jspdf-autotable';
import moment from "moment";
import {useHistory} from "react-router-dom";
import CustomStore from "devextreme/data/custom_store";
import axios from "../../axios";
import DataSource from "devextreme/data/data_source";
import {ComparativeTable} from "../comparative-table";

// Product quotation definition
export type ProductQuotation = {
    // Lista prodotti all'interno della quotazione
    products: {
        // Dettaglio prodotto
        product: Product
        // Fornitore
        provider: ProductProvider
        // Quantità richiesta
        requiredQuantity: number
        // Quantità ordinata
        orderedQuantity: number
        // Prezzo totale
        totalPrice: number
        // Disponibile
        available: boolean
    }[]
    // Tabella dei prezzi
    prices: {
        // Tipologia di prezzo
        type: PriceType
        // Descrizione
        description: string
        // Prezzo
        price: number
    }[]
}

export enum PriceType {
    default
}

type ProductQuotationProps = {
    defaultFilter?: FilterType | null
}

export type FilterType = {
    products: any[],
    providers: any[],
    split: boolean
    stock: boolean
}

export const ProductQuotation = ({defaultFilter}: ProductQuotationProps): ReactElement => {

    const history = useHistory();
    const grid = useRef<DataGrid | null>(null);
    const popup = useRef<Popup | null>(null);

    const [providers, setProviders] = useState<Provider[]>([]);
    useEffect(() => {
        // Load providers
        axios.get('/providers', {params: {skip: 0, limit: 10}}).then(results => setProviders(results.data.data));
    }, []);

    const [dataSource, setDataSource] = useState<ProductQuotation | null>(null);
    const [filter, setFilter] = useState<FilterType>(defaultFilter || {
        products: [],
        providers: [],
        split: true,
        stock: true
    });

    useEffect(() => {
        // Get quotation
        axios.post('/quotation', filter).then(results => {
            // Set dataSource
            setDataSource({
                products: results.data.data.items,
                prices: results.data.data.prices
            });
        });
    }, [filter]);

    // Table export
    const exportGrid = () => {
        if (grid.current) {
            const doc = new jsPdf({orientation: 'l', format: 'a3'});
            const dataGrid = grid.current.instance;
            // Export
            exportDataGrid({
                jsPDFDocument: doc,
                component: dataGrid
            }).then(() => {
                // doc.autoPrint();
                // doc.output('dataurlnewwindow');
                doc.save('Quotazione_' + moment().format('DD_MM_YYYY') + ' .pdf');
            });
        }
    };

    // Providers button
    const [filterProviders, setFilterProviders] = useState<any[]>([]);

    const setupProvidersData = useCallback(() => {
        if (!dataSource) return;
        // Empty lists
        let buttons_: any[] = [];
        // Check filter for enable ALL option
        if (filter.split) {
            // Add ALL option
            buttons_.push({
                location: 'before',
                template: (this_: any, number: number, element: any) => {
                    // Render PDF
                    ReactDOM.render(
                        <Button
                            text='Tutti'
                            type='default'
                            onClick={() => setFilter({
                                ...filter,
                                providers: []
                            })}
                        />, element
                    );
                }
            });
        }
        // Get all present providers with status quantity
        let flags: { [key: string]: null | boolean } = {};
        for (const quota of dataSource.products) {
            // Each provider
            for (const productProvider of quota.product.providers) {
                // Check if not present
                if (productProvider.quantity === null) {
                    flags[productProvider.provider.name] = null
                }
                // Check if value is true can change
                else if (typeof flags[productProvider.provider.name] !== 'boolean' || flags[productProvider.provider.name]) {
                    // Set / Update the flag
                    flags[productProvider.provider.name] = productProvider.quantity >= quota.requiredQuantity;
                }
            }
        }
        // Each all providers
        for (const provider of providers) {
            // Set btn type
            let btnType: any = 'normal';
            if (typeof flags[provider.name] === 'boolean') {
                btnType = flags[provider.name] ? 'success' : 'danger'
            }
            // Add btn for the provider
            buttons_.push({
                location: 'before',
                disabled: btnType === 'normal',
                template: (this_: any, number: number, element: any) => {
                    // Render PDF
                    ReactDOM.render(
                        <Button
                            text={provider.name}
                            type={btnType}
                            onClick={() => setFilter({
                                ...filter,
                                providers: [provider.id]
                            })}
                        />, element
                    );
                }
            })
        }
        // Set state
        setFilterProviders(buttons_);
    }, [providers, dataSource, filter])
    useEffect(() => {
        // Call on every change
        setupProvidersData();
    }, [setupProvidersData]);

    // Comparative table render
    const renderComparativeTable = useCallback((): ReactElement => {
        if (!dataSource) return <></>;
        // Get all product providers
        let productProviders: ProductProvider[] = [];
        for (const quota of dataSource.products) {
            let mapped = quota.product.providers.map(p => {
                return {...p, listPrice: quota.product.listPrice, code: quota.product.code}
            });
            // Each map and add to list (DISTINCT CODE for PROVIDER
            for (const mappedElement of mapped) {
                let index_ = productProviders.findIndex(pp => pp.code === mappedElement.code && pp.provider.id === mappedElement.provider.id);
                if (index_ === -1) {
                    // Add element
                    productProviders.push(mappedElement);
                }
            }
        }
        // Render table
        return (
            <ProductProviderTable
                enableExport={true}
                dataSource={
                    new DataSource({
                        store: productProviders,
                        group: "code"
                    })
                }
            />
        )
    }, [dataSource]);

    return (
        <div className="product-quotation">
            <DataGrid
                ref={grid}
                dataSource={dataSource ? dataSource.products : []} // Empty ad default
                keyExpr='product.code'
                columns={[
                    {dataField: 'product.code', dataType: 'string', caption: 'Codice originale', allowEditing: false},
                    {
                        caption: 'Fornitore',
                        columns: [
                            {dataField: 'provider.provider.name', dataType: 'string', caption: 'Nominativo'},
                            {dataField: 'provider.code', dataType: 'string', caption: 'Codice Fornitore'},
                            {dataField: 'provider.provider.internaCode', dataType: 'string', caption: 'Cod. Interno'},
                        ]
                    },
                    {
                        dataField: 'product.userCode',
                        dataType: 'string',
                        caption: 'Codice utente',
                        customizeText: (data: any) => data.value || '--',
                        hidingPriority: 1
                    },
                    {dataField: 'product.producer', dataType: 'object', caption: 'Produttore', hidingPriority: 2},
                    {dataField: 'product.description', dataType: 'string', caption: 'Descrizione', hidingPriority: 0},
                    // Quantity
                    {dataField: 'provider.quantity', dataType: 'number', caption: 'Stock', hidingPriority: 3},
                    {
                        dataField: 'requiredQuantity',
                        dataType: 'number',
                        caption: 'Qta Ord / Rich',
                        cellTemplate: (element, cellData) => {
                            // Render into element
                            ReactDOM.render(
                                <>{cellData.data?.orderedQuantity} di {cellData.data?.requiredQuantity}</>,
                                element
                            );
                        }
                    },
                    // Price
                    {
                        dataField: 'product.listPrice',
                        dataType: 'number',
                        caption: 'Prezzo Listino',
                        format: {
                            type: 'currency',
                            precision: 2
                        },
                        hidingPriority: 4
                    },
                    {
                        dataField: 'provider.price',
                        dataType: 'number',
                        caption: 'Prezzo Finale',
                        format: {
                            type: 'currency',
                            precision: 2
                        }
                    },
                    {
                        dataField: 'totalPrice',
                        dataType: 'number',
                        caption: 'Totale',
                        format: {
                            type: 'currency',
                            precision: 2
                        }
                    }
                ]}
                columnAutoWidth={true}
                columnHidingEnabled={true}
                onCellPrepared={(args) => {
                    // Check data
                    if (args.rowType === 'data') {
                        if (!args.data.available) {
                            args.cellElement.style.backgroundColor = '#ff7171';
                        } else if (args.data.provider.quantity === 0 || args.data.orderedQuantity > args.data.provider.quantity) {
                            args.cellElement.style.backgroundColor = '#ffeb6d';
                        }
                    }
                }}
                // Remote
                remoteOperations={{
                    filtering: true
                }}
                // Toolbar
                toolbar={{
                    items: [
                        {
                            location: 'before',
                            template: (this_, number, element) => {
                                // Render button
                                ReactDOM.render(
                                    <Button
                                        type='back'
                                        onClick={() => history.goBack()}
                                    />, element
                                );
                            }
                        },
                        ...filterProviders,
                        {
                            template: (this_, number, element) => {
                                // Render button
                                ReactDOM.render(
                                    <div className="flex justify-around items-center w-28">
                                        <span className='text-lg'>Split</span>
                                        <Switch
                                            defaultValue={filter.split}
                                            onValueChanged={
                                                (args) => setFilter({
                                                    ...filter,
                                                    split: args.value,
                                                    stock: args.value
                                                })
                                            }
                                        />
                                    </div>, element
                                );
                            }
                        },
                        {
                            template: (this_, number, element) => {
                                // Render button
                                ReactDOM.render(
                                    <div className="flex justify-around items-center w-28">
                                        <span className='text-lg'>Stock</span>
                                        <Switch
                                            defaultValue={filter.stock}
                                            onValueChanged={
                                                (args) => setFilter({
                                                    ...filter,
                                                    stock: args.value
                                                })
                                            }
                                        />
                                    </div>, element
                                );
                            }
                        },
                        'exportButton',
                        {
                            template: (this_, number, element) => {
                                // Render PDF
                                ReactDOM.render(
                                    <Button
                                        icon='pdffile'
                                        onClick={exportGrid}
                                    />, element
                                );
                            }
                        },
                        {
                            template: (this_, number, element) => {
                                // Render button
                                ReactDOM.render(
                                    <Button
                                        type='normal'
                                        text='Tabella Comparativa'
                                        onClick={() => popup.current?.instance.show()}
                                    />, element
                                );
                            }
                        }
                    ]
                }}
                // Export
                export={{enabled: true}}
                onExporting={(args) => {
                    // Update file name
                    args.fileName = 'Quotazione_' + moment().format('DD_MM_YYYY');
                }}
                // Summary
                summary={{
                    calculateCustomSummary: (args) => {
                        // Get price
                        let price_ = dataSource?.prices.find(p => p.description === args.name);
                        // Set price value
                        args.totalValue = price_?.price || 0;
                    },
                    // Map quotation prices
                    totalItems: [
                        ...dataSource?.prices.map((p) => {
                            let align: any = 'left';
                            return {
                                name: p.description,
                                summaryType: 'custom',
                                alignment: align,
                                customizeText: () => p.description,
                                showInColumn: 'provider.price'
                            }
                        }) || [],
                        ...dataSource?.prices.map((p) => {
                            return {
                                name: p.description,
                                summaryType: 'custom',
                                valueFormat: {
                                    type: 'currency',
                                    precision: 2
                                },
                                displayFormat: '{0}',
                                showInColumn: 'totalPrice'
                            }
                        }) || []
                    ]
                }}
            />
            {/* Dialog table */}
            <Popup
                ref={popup}
                title='Tabella Comparativa'
                showCloseButton={true}
                contentRender={renderComparativeTable}
            />
        </div>
    )
}