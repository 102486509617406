import {ReactElement} from "react";
import {HeaderTitle} from "../header-title";

type PanelProps = {
    headerTitle?: {
        title: string,
        inverse?: boolean
        cssClass?: string
    }
    cssClass?: string
    children: any
}

export const Panel = ({headerTitle, cssClass, children}: PanelProps): ReactElement => {
    return (
        <div className={`panel bg-white ${cssClass}`}>
            {/* Title */}
            {headerTitle ? <HeaderTitle title={headerTitle.title} inverse={headerTitle.inverse} cssClass={headerTitle.cssClass}/> : <></>}
            {/* Content */}
            <div className="p-1">
                {children}
            </div>
        </div>
    )
}